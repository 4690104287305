import "@babel/polyfill";
import "mutationobserver-shim";
import "core-js/stable";

import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import VueSimpleAlert from "vue-simple-alert";
import store from "./store";
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from "vue2-google-maps";
import VueSession from 'vue-session'



import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueSession);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);



window.$ = window.jQuery = require("jquery");

Vue.config.performance = true;
Vue.use(VueGeolocation);

//API KEY PRUEBA: AIzaSyDp3Ud3SixagW8bJOwtosy5bBMB5JRNS_k
//API KEY MARTIN: AIzaSyBb_DpEm-XpdvIiyWIWHSkQTDVrkjAkgJc
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7WUS1QPkPb0RjHP5Qkdetb4RjQn17V6g",
    libraries : 'places',
    region: 'PE-LIM',
    language: 'es',
  },
  installComponents: true
});

Vue.use(CoreuiVue);
Vue.use(VueSimpleAlert);
//Vue.use(dotenv);
Vue.prototype.$log = console.log.bind(console);
new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
  created: function() {
    //this.RUTA_SERVIDOR= process.env.VUE_APP_RUTA_API
  },
});